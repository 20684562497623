import React from "react";
import ContentContainer from "../container/ContentContainer";
import ButtonAction from "./ButtonAction";
import { useStaticQuery, graphql } from "gatsby";

const ContactExpert = () => {
  const heroImage = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "logs.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <ContentContainer
      containerClassName="mb-4 w-full"
      className="w-full max-w-full sm:mx-5"
      image={heroImage.backGround}
    >
      <div className="flex flex-col justify-center items-center text-white gap-y-10 sm:gap-y-8 mt-32 mb-20">
        <div className="flex flex-col max-w-4xl text-xl sm:text-3xl text-center px-9 gap-y-4">
          <p>
            We strive to provide the right products & the right service at the
            right price. National Wood Products
          </p>
          <p className="italic">-The Right Choice&#8482;</p>
        </div>
        <ButtonAction
          className="bg-actionBlue hover:bg-opacity-50 text-white px-24 sm:px-32"
          to="/contact"
        >
          Talk to an expert
        </ButtonAction>
      </div>
    </ContentContainer>
  );
};

export default ContactExpert;
